import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

// Components
import { BaseContainer, BaseGrid, BaseColumn } from '@/components/primitives';
import { BaseButton, BaseSpacer, BaseText } from '@/components/partials';
import { BaseForm, BaseInput } from '@/components/partials/forms';
import { LoadingSpinner } from '@/components/partials';
import { SearchSuggestions } from '@/components/features';

// Helpers
import { getFormError, HelperFormErrors } from '@/helpers/form';

// Types
import type { SearchForm } from '@/types/form';
import type { SearchTypes } from '@/constants/searchTypes';
import type { Component, ComputedRef } from 'vue';

// setup

export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const store = useStore();

// reactive
const state = reactive({
  searchForm: {
    searchTerm: '',
    searchType: 'product' as SearchTypes.SEARCH_TYPE_PRODUCT,
    errors: new HelperFormErrors(),
  },
});

const loading: ComputedRef<Component> = computed(() => store.getters['search/getStoreLoading']);
const message: ComputedRef<Component> = computed(() => store.state.search.message);
const search = async (item: string) => {
  state.searchForm.searchTerm = item;
  await store.dispatch('search/sendRequest', state.searchForm);
};
const errors: ComputedRef<Record<string, string[]>> = computed(() => store.getters['product/getStoreErrors']);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), {
    class: _normalizeClass(['v-stock-replenish-search', { 'is-fetching': loading.value }])
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseText), {
        tag: "h1",
        size: { default: '3xl' }
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Stock Replenishment")
        ])),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer), { size: "1" }),
      _createVNode(_unref(SearchSuggestions), {
        "onItem:click": _cache[0] || (_cache[0] = ($event: any) => (search($event)))
      }),
      (loading.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_unref(BaseSpacer), { size: "2" }),
            _createVNode(_unref(LoadingSpinner))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})